/* src/components/NavHeader.css */

.nav-header {
    background: linear-gradient(90deg, #6F41CC, #2B80FF);
    color: white;
    padding: 20px 0;
  }
  
  .nav-header-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
  }
  
  .logo-img {
    width: 5em;
    height: 5em;
    cursor: pointer;
  }
  
  .nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 15px;
    margin: 0;
  }
  
  .nav a {
    text-decoration: none;
    color: white;
    font-size: 1em;
    transition: color 0.3s;
  }
  
  .nav a:hover {
    color: #ffeb3b;
  }
  
  .download-btn {
    text-decoration: none;
    color: white;
    background-color: #ff5722;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s;
    font-weight: bold;
  }
  
  .download-btn:hover {
    background-color: #e64a19;
  }
  
  .hamburger-icon {
    display: none;
    color: white;
    font-size: 1.5em;
    cursor: pointer;
  }
  
  .nav.open {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .nav.open ul {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .nav.open a {
    font-size: 1.5em;
  }
  
  @media (max-width: 768px) {
    .nav-header-content {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
    }
  
    .hamburger-icon {
      display: block;
      z-index: 1000;
    }
  
    .nav {
      display: none;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  
    .nav.open {
      display: flex;
    }
  }
  