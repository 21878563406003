/* SectionA.css */

.body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    background-color: #f5f5f5;
    /* Optional: adds background color to the body section */
}

.body-content {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    max-width: 1200px;
    /* Maximum width of the content */
    width: 100%;
    padding: 0 20px;
    /* Padding to ensure there's space on the sides */
    box-sizing: border-box;
    /* Ensures padding is included in the width */
}

.body-image img {
    max-width: 80%;
    height: auto;
    border-radius: 10px;
}

.body-text {
    max-width: 50%;
}

.orange-title {
    color: orange;
    font-size: 1.2em;
    margin-bottom: 10px;
}

.black-title {
    color: #6F41CC;
    font-size: 2.5em;
    margin-bottom: 10px;
}

.black-paragraph {
    color: black;
    font-size: 1.2em;
    margin-bottom: 20px;
}

.bullet-points {
    list-style-type: none;
    font-size: 1.2em;
    padding: 0px;
    color: black;
}

.bullet-points li {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .body-content {
        flex-direction: column;
        align-items: center;
    }

    .body-image {
        text-align: center;
    }

    .body-text {
        max-width: 100%;
        text-align: center;
        padding: 10px
    }

    .bullet-points {
        text-align: left;
    }

    .orange-title {
        font-size: 1em;
    }

    .black-title {
        font-size: 1.5em;
    }

    .black-paragraph,
    .bullet-points {
        font-size: 1em;
    }

    .orange-title,
    .black-title,
    .black-paragraph,
    .bullet-points {
        text-align: left;
    }
}