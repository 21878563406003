.header {
    background: linear-gradient(90deg, #6F41CC, #2B80FF);
    color: white;
    padding: 20px 0;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
}

.logo-img {
    width: 5em;
    height: 5em;
}

.nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 15px;
    margin: 0;
}

.nav a {
    text-decoration: none;
    color: white;
    font-size: 1em;
    transition: color 0.3s;
}

.nav a:hover {
    color: #ffeb3b;
}

.play-btn-container {
    align-items: center;
}

.play-img {
    width: 9.5em;
    margin-top: 10px;
}

.hamburger-icon {
    display: none;
    color: white;
    font-size: 1.5em;
    cursor: pointer;
}

.nav.open {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.nav.open ul {
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.nav.open a {
    font-size: 1.5em;
}

.promo-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
    text-align: left;
}

.promo-text {
    max-width: 50%;
}

.promo-text h1 {
    font-size: 2em;
    margin-bottom: 10px;
}

.promo-text p {
    margin-top: 20px;
    margin-bottom: 40px;
    font-style: italic;
}

.app-arriving {
    text-decoration: none;
    color: black;
    background-color: white;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s;
    font-weight: bold;
}

.app-arriving:hover {
    color: white;
    background-color: black;
}

.promo-image {
    text-align: center;
}

.promo-image img {
    max-width: 60%;
    height: auto;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .header-content {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
    }

    .hamburger-icon {
        display: block;
        z-index: 1000;
    }

    .nav {
        display: none;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .nav.open {
        display: flex;
    }

    .promo-section {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 10px;
    }

    .promo-text {
        max-width: 100%;
        font-size: 0.9em;
        padding: 10px;
    }

    .promo-text h1 {
        font-size: 1.5em;
    }

    .promo-text p,
    .app-arriving {
        font-size: 0.9em;
    }

    .promo-image img {
        max-width: 100%;
    }
}