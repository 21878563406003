/* src/component/privacyPolicy.css */

.privacy-policy-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
}

.privacy-policy-heading-h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    text-align: center;
}

.privacy-policy-heading-h2 {
    font-size: 2em;
    margin-top: 20px;
    margin-bottom: 10px;
}

.privacy-policy-heading-h3 {
    font-size: 1.5em;
    margin-top: 15px;
    margin-bottom: 10px;
}

.privacy-policy-text {
    font-size: 1em;
    margin-bottom: 10px;
}

.privacy-policy-list {
    list-style: disc;
    padding-left: 20px;
    margin-bottom: 10px;
}

.privacy-policy-list-item {
    margin-bottom: 5px;
}

.privacy-policy-list-item a {
    color: blue;
    text-decoration: none;
}

.privacy-policy-list-item a:hover {
    color: darkblue;
}

@media (max-width: 768px) { 
    .privacy-policy-heading-h1 {
        text-align: left;
    }
}