.body-section-b {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    background-color: #FFFAFA;
    border-top:1px solid rgb(206, 201, 201);
    /* Optional: adds background color to the body section */
}

.body-content {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    max-width: 1200px;
    /* Maximum width of the content */
    width: 100%;
    padding: 0 20px;
    /* Padding to ensure there's space on the sides */
    box-sizing: border-box;
    /* Ensures padding is included in the width */
}

.body-image {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subject-title {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 1em;
    text-align: center;
    background: #FF5722;
    color: white;
    border-radius: 10px;
    padding: 8px;
}

.body-image img {
    max-width: 80%;
    height: auto;
    border-radius: 10px;
}

.body-text {
    max-width: 50%;
}

.orange-title {
    color: orange;
    font-size: 1.2em;
    margin-bottom: 10px;
}

.black-title {
    color: #6F41CC;
    font-size: 2.5em;
    margin-bottom: 10px;
}

.black-paragraph {
    color: black;
    font-size: 1.2em;
    margin-bottom: 20px;
}

.button-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 415px;
    gap: 10px;
    margin-top: 20px;
    padding-right: 10px;
}

.image-button {
    background-color: #5E50D8;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.image-button:hover,
.image-button.active {
    background-color: #ff5722;
}

@media (max-width: 768px) {
    .body-content {
        flex-direction: column;
        align-items: center;
    }

    .body-image {
        text-align: center;
    }

    .body-text {
        max-width: 100%;
        text-align: center;
        padding: 10px;
    }

    /* .button-container {
        max-height: none;
    } */

    .button-container {
        max-height: 250px;
        padding-right: 5px;
        overflow-y: scroll;
    }

    .orange-title {
        display: none;
    }

    .black-title {
        font-size: 1.2em;
    }

    .black-paragraph {
        display: none;
    }
}

/* Custom scrollbar styles */
.button-container::-webkit-scrollbar {
    width: 8px;
}

.button-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.button-container::-webkit-scrollbar-thumb {
    background-color: orange;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

.button-container::-webkit-scrollbar-thumb:hover {
    background-color: #ff5722;
}
